import React from "react";
import ReactExport from "react-export-excel";
import Swal from 'sweetalert2';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

function DownloadExcel(props) {

    const fileName = `payments_${props.brand}_${props.initDate}_${props.endDate}`;

    const genarateFile = () => {
        setTimeout(
            function () {
                Swal.fire({
                    title: 'Reporte generado con exito',
                    type: 'success'
                })
            },
            1000
        );
    }

    return (
        <ExcelFile
            filename={fileName}
            element={<button
                onClick={genarateFile}
                disabled={(props.dataSet.length === 0)}
                className="btn btn-primary w-100 mb-3 mt-2">Generar Reporte</button>}>
            <ExcelSheet data={props.dataSet} name={props.brand}>
                <ExcelColumn label="TIENDA" value="branchId" />
                <ExcelColumn label="NUMERO DE CUENTA" value="accountNumber" />
                <ExcelColumn label="FECHA" value="applicationDate" />
                <ExcelColumn label="CODIGO BANCARIO" value="bankCode" />
                <ExcelColumn label="DESCRIPCION" value="description" />
                <ExcelColumn label="SIGNO" value="sign" />
                <ExcelColumn label="IMPORTE" value="amount" />
                <ExcelColumn label="AFILIACION" value="affiliation" />
                {/* <ExcelColumn label="Marital Status"
                        value={(col) => col.is_married ? "Married" : "Single"} /> */}
            </ExcelSheet>
        </ExcelFile>
    );
}

export default DownloadExcel;