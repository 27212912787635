import React from "react";


class Selects extends React.Component {

    render() {
        return (
            <div className="col-12 col-sm-12 col-md-12 col-lg-12 pl-0 pr-0 ">
                <select
                    onChange={this.props.onChange}
                    id={this.props.id}
                    className="form-control"
                    value={this.props.value}
                >
                    <option key={0} value={0}>
                        {'-- Seleccione Marca --'}
                    </option>
                    {this.props.list.map(item => {
                        return (
                            <option key={item.id} value={item.id}>
                                {item.name}
                            </option>
                        );
                    })}
                </select>
            </div>

        );
    }
}

export default Selects;
