import React, { Fragment } from "react";
import { Auth } from "aws-amplify";
import { Link } from "react-router-dom";
import AlseaLogo from "../images/general/ALSEA-blanco.png";
import ModalInfo from "../components/ModalInfo";
import api from "../services/ServiceGeneral";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import Swal from 'sweetalert2';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemText from '@material-ui/core/ListItemText';
import Select from '@material-ui/core/Select';
import Checkbox from '@material-ui/core/Checkbox';
import FormControl from '@material-ui/core/FormControl';
import { makeStyles } from '@material-ui/core/styles';


export default function Register() {
  const [name, guardarName] = React.useState('');
  const [paymentMethod , guardarPaymentMethod] = React.useState('');
  const [password, guardarPassword] = React.useState('');
  const [email, guardarEmail] = React.useState('');
  const [countries, guardarCountries] = React.useState([]);
  const [brands, guardarBrands] = React.useState([]);
  const [branches, guardarBranches] = React.useState([]);
  const [countryId, guardarCountryId] = React.useState("");
  const [brandId, guardarBrandId] = React.useState("0");
  const [branchId, guardarBranchId] = React.useState("0");
  const [branchName, guardarBranchName] = React.useState('');
  const [groups, guardarGroup] = React.useState('');
  const [error, guardarError] = React.useState('');

  const [disableCountry, guardarDisableCountry] = React.useState(true);
  const [disableBrands, guardarDisableBrands] = React.useState(true);
  const [disableBranchs, guardarDisableBranchs] = React.useState(true);
  const names = [
    {id:1,name:"EFECTIVO"},
    {id:2,name:"TPV"},
    {id:3,name:"OPENPAY"},
    {id:4,name:"WOW"},
    {id:5,name:"INTERMEDIARIO"},
    {id:6,name:"CONTRAENTREGA"},
    {id:126,name:"RAPPI"},
    {id:137,name:"MERCADOPAGO"},
  ];

  const getNames =(arr)=>{
    let name="";
    for (let i in arr){
      name+=arr[i]+",";
    }
    return name;
  };
  const [personName, setPersonName] = React.useState([]);
  const [brandName, setBrandName] = React.useState([]);
  const [selectAll, setSelectAll] = React.useState(false);
  const [selectAll1, setSelectAll1] = React.useState(false);
  const [isAdmin, setIsAdmin] = React.useState(false);

  const [textBrand, setTextBrand] = React.useState("Selecciona la(s) marca(s)");
  const [textCountry, setTextCountry] = React.useState("Selecciona el país");
  const handleChange2 = event => {
    if(event.target.value.indexOf("all") > -1){
      if(!selectAll1){
        setBrandName(brands.map(a => a.id));
      }else {
        setBrandName([]);
      }
      setSelectAll1(!selectAll1);
    }else {
      setBrandName(event.target.value);
    }
    // guardarPaymentMethod
  };
  const handleChange = event => {
    if(event.target.value.indexOf("all") > -1){
      if(!selectAll){
        setPersonName(names.map(a => a.id));
      }else {
        setPersonName([]);
      }
      setSelectAll(!selectAll);
    }else {
      setPersonName(event.target.value);
    }
    // guardarPaymentMethod
  };
  const [labelWidth, setLabelWidth] = React.useState(100);

  React.useEffect(()=>{
    guardarPaymentMethod(personName.join(',').toString());
  },[personName]);
  React.useEffect(()=>{
    guardarBrandId(brandName.join(',').toString());
  },[brandName]);

  React.useEffect(() => {
    const consultarAPI = async () => {
      const dataCountries = await api.services.listCountries();
      guardarCountries(dataCountries.content);
    }
    consultarAPI();
  }, []);


  const handleChangeCountry = async e => {
    setBrandName([]);
    setSelectAll1(false);
    const countryId = e.target.value;
    const dataBrands = await api.services.listBrands(countryId);
    guardarBrands(dataBrands.content);
    guardarCountryId(countryId);
    guardarBranches([]);
    guardarBrandId("0");
    guardarBranchId("0");
    guardarBranchName('');
  };

  const handleClickBrand = async e => {
    try {
      const brandId = e.target.value;
      let dataBranches = await api.services.listBranches(countryId, brandId);
      console.info(dataBranches.content);
      guardarBranches(dataBranches.content);
      guardarBrandId(brandId);
      guardarBranchId(0);
      guardarBranchName('');

    } catch (error) {
      guardarError(error);
    }
  };


  const handleSubmit = async event => {
    event.preventDefault();


    console.info("username-email: " + email);
    console.info("password: " + password);
    console.info("name: " + name);
    console.info("last_name: " + paymentMethod);
    console.info("email: " + email);

    console.info("country: " + countryId);
    console.info("brand: " + brandId);
    console.info("branch: " + branchId);
    console.info("branch_name: " + branchName);

    console.info("groups: " + groups);
    debugger;
    if (validateParams()) {
      try {
        await Auth.signUp({
          username: email,
          password,
          attributes: {
            email: email,
            name: name,
            "custom:country": (isAdmin)?"0":String(countryId),
            "custom:brand": (isAdmin)?"0":String(brandId),
            "custom:branch": String(branchId),
            "custom:paymentMethod": String(paymentMethod),
            "custom:group": String(groups),
            "custom:options": ""
          }
        });
        console.info("Exito!!");

        Swal.fire({
          title: '',
          text: 'El Usuario se registro correctamente',
          type: 'success'
        })

        clenForm();
      } catch (error) {
        console.info(error);
        guardarError(error);
        Swal.fire({
          type: 'error',
          title: '',
          text: `Hubo un error: ${error.message}`
        });
      }
    }
  };

  const clenForm = () => {
    setBrandName([]);
    setPersonName([]);
    setSelectAll1(false);
    setSelectAll(false);
    setIsAdmin(false);
    guardarName('');
    guardarPassword('');
    guardarEmail('');
    guardarBrands([]);
    guardarBranches([]);
    guardarCountryId("");
    guardarBrandId(0);
    guardarBranchId(0);
    guardarBranchName('');
    guardarGroup('');
    guardarDisableCountry(true);
    guardarDisableBrands(true);
    guardarDisableBranchs(true);
    document.getElementById("form-register").reset();
  }

  const validateParams = () => {
    console.info("****************************");
    console.info("Validaciones::::");
    let errors = [];

    if (name === '') {
      errors.push("Name empty/undefined/null");
    }

    if (paymentMethod === '') {
      errors.push("paymentMethod empty/undefined/null");
    }

    if (email === '') {
      errors.push("Email empty/undefined/null");
    }

    if (password === '') {
      errors.push("Password empty/undefined/null");
    }

    if (groups === '') {
      errors.push("Grupo empty/undefined/null");
    } else if (groups !== "Admin" && groups !== "Coordinador") {

      if (countryId === "0") {
        errors.push("countryId empty/undefined/null");
      }
      if (brandId === "0") {
        errors.push("Marca empty/undefined/null");
      }

      if (groups === "Gerente") {
        if (branchId === "0") {
          errors.push("Sucursal empty/undefined/null");
        }
      }
    }

    console.info(errors);
    return errors.length === 0;
  }

  const handleChangeGroup = e => {
    setIsAdmin(false);
    setBrandName([]);
    setSelectAll1(false);
    guardarGroup(e.target.value)
    guardarDisableCountry(false);
    guardarDisableBranchs(false);
    guardarDisableBrands(false);
    guardarCountryId("");
    guardarBranches([]);
    guardarBrands([]);
    guardarBrandId("");
    guardarBranchId("0");
    guardarBranchName('');
    if (e.target.value === 'Admin') {
      setTextCountry("Todos los paises");
      setTextBrand("Todas las marcas");
      setIsAdmin(true);
      guardarDisableCountry(true);
      guardarDisableBranchs(true);
      guardarDisableBrands(true);
    } else if (e.target.value === 'Marca') {
      guardarDisableCountry(true);
      guardarDisableBranchs(true);
    }
  };

  const handleChangeBranch = e => {
    const branch = document.getElementById("branch");
    const branchName = branch.options[e.target.selectedIndex].text;
    guardarBranchId(e.target.value);
    guardarBranchName(branchName);
  };

  return (
      <Fragment>
        <div className="container-fluid-login">
          <div className="login-a">
            <div className="Return-Sign-Content">
              <Link to={'/dashboard'}><ArrowBackIcon /></Link>
            </div>
            <div className="login-logo">
              <img alt="Logo Alsea" src={AlseaLogo} />
              <p className="app-title font-9">Switch Pagos / New Users</p>
            </div>
          </div>



          <div className="Return-Sign-Content-movile">
            <Link to={'/dashboard'} ><ArrowBackIcon /></Link>
          </div>

          <div className="login-b">
            <div className=" card-login">
              <div className="card-head display_justify-content_center display_align_items_center">

              </div>


              <div className="card-body">
                <h5 className="card-title-login mt-3">Registrar usuario</h5>
                <form
                    id="form-register"
                    onSubmit={handleSubmit}
                    className="row">

                  <div className="col-12 col-xs-12 col-sm-12  col-md-12 col-lg-12">
                    <div className="form-group">
                      <input
                          placeholder="Nombre"
                          className="form-control"
                          id="name"
                          onChange={(e) => guardarName(e.target.value)}
                      />
                    </div>
                    <div className="form-group">
                      <input
                          placeholder="Contraseña"
                          type="password"
                          className="form-control"
                          id="password"
                          onChange={(e) => guardarPassword(e.target.value)}
                      />
                    </div>

                    <div className="form-group">
                      <input
                          placeholder="Email"
                          type="email"
                          className="form-control"
                          id="email"
                          onChange={(e) => guardarEmail(e.target.value)}
                      />
                    </div>

                    <div className="form-group">
                      {/*<input*/}
                      {/*    placeholder="Payment Method"*/}
                      {/*    type="text"*/}
                      {/*    className="form-control"*/}
                      {/*    id="paymentMethod"*/}
                      {/*    onChange={(e) => guardarPaymentMethod(e.target.value)}*/}

                      {/*/>*/}
                      <FormControl margin='dense' variant="outlined"  size="small" className="w-100 mw-100">
                        <InputLabel id="demo-mutiple-checkbox-label">Payment Method</InputLabel>
                        <Select
                            labelId="demo-mutiple-checkbox-label"
                            id="demo-mutiple-checkbox"
                            multiple
                            value={personName}
                            onChange={handleChange}
                            labelWidth={labelWidth}
                            renderValue={selected => selected.map((e,i)=>{
                              return (i>0?", ":"")+names.find(x => x.id === e).name
                            })}
                        >
                          <MenuItem key="all" value="all">
                            <ListItemText primary={selectAll?"Remover todos":"Seleccionar todos"} />
                          </MenuItem>
                          {names.map(name => (
                              <MenuItem key={name.id} value={name.id}>
                                <Checkbox checked={personName.indexOf(name.id) > -1} />
                                <ListItemText primary={name.name} />
                              </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </div>


                    <div className="form-group">
                      {/*<Select className="form-control" id="group" onChange={handleChangeGroup} >*/}
                      {/*  <option value="">Seleccionar Grupo</option>*/}
                      {/*  <option value="Admin">Administrador</option>*/}
                      {/*  <option value="Coordinador">Coordinador</option>*/}
                      {/*  <option value="Gerente">Gerente</option>*/}
                      {/*  <option value="Marca">Marca</option>*/}
                      {/*</Select>*/}
                      <FormControl margin='dense' variant="outlined"  size="small" className="w-100 mw-100">
                        <InputLabel id="marca-mutiple-checkbox-label">Seleccionar Grupo</InputLabel>
                        <Select
                            labelId="marca-mutiple-checkbox-label"
                            id="marca-mutiple-checkbox"
                            value={groups}
                            onChange={handleChangeGroup}
                            labelWidth="110"
                            renderValue={selected => selected}
                        >
                          <MenuItem value="Admin">
                            <ListItemText primary="Administrador" />
                          </MenuItem>
                          <MenuItem value="Coordinador">
                            <ListItemText primary="Coordinador" />
                          </MenuItem>
                          <MenuItem  value="Gerente">
                            <ListItemText primary="Gerente" />
                          </MenuItem>
                          <MenuItem value="Marca">
                            <ListItemText primary="Marca" />
                          </MenuItem>
                        </Select>
                      </FormControl>
                    </div>

                    <div className="form-group">
                      {/*<select*/}
                      {/*    className="form-control"*/}
                      {/*    id="country"*/}
                      {/*    onChange={handleChangeCountry}*/}
                      {/*    value={countryId}*/}
                      {/*    disabled={disableCountry}*/}
                      {/*>*/}
                      {/*  <option key="0" value="0">{textCountry}Selecciona el país</option>*/}
                      {/*  {countries.map(country => {*/}
                      {/*    return <option key={country.id} value={country.id}>{country.name}</option>*/}
                      {/*  })}*/}
                      {/*</select>*/}
                      <FormControl margin='dense' variant="outlined"  size="small" className="w-100 mw-100">
                        <InputLabel id="marca-mutiple-checkbox-label">{textCountry}</InputLabel>
                        <Select
                            labelId="marca-mutiple-checkbox-label"
                            id="marca-mutiple-checkbox"
                            value={countryId}
                            disabled={disableCountry}
                            onChange={handleChangeCountry}
                            labelWidth="110"
                            renderValue={selected => countries.find(x=>x.id===selected).name}
                        >
                          {countries.map(name => (
                              <MenuItem key={name.id} value={name.id}>
                                <ListItemText primary={name.name} />
                              </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </div>


                    <div className="form-group">
                      {/*<select*/}
                      {/*    className="form-control"*/}
                      {/*    id="brand"*/}
                      {/*    onChange={handleClickBrand}*/}
                      {/*    disabled={disableBrands}*/}
                      {/*>*/}
                      {/*  <option key="0" value="0">Todas las marca</option>*/}
                      {/*  {brands.map(brand => {*/}
                      {/*    return <option key={brand.id} value={brand.id}>{brand.name}</option>*/}
                      {/*  })}*/}
                      {/*</select>*/}
                      <FormControl margin='dense' variant="outlined"  size="small" className="w-100 mw-100">
                        <InputLabel id="marca-mutiple-checkbox-label">{textBrand}</InputLabel>
                        <Select
                            labelId="marca-mutiple-checkbox-label"
                            id="marca-mutiple-checkbox"
                            multiple
                            value={brandName}
                            disabled={disableBrands}
                            onChange={handleChange2}
                            labelWidth="150"
                            renderValue={selected => selected.map((e,i)=>{
                                return (i>0?", ":"")+(brands.length!==0?brands.find(x => x.id === e).name:null);
                            })}
                        >
                          {brands.length>0 &&
                          <MenuItem key="all" value="all">
                            <ListItemText primary={selectAll1?"Remover todos":"Seleccionar todos"} />
                          </MenuItem>

                          }

                          {brands.map(name => (
                              <MenuItem key={name.id} value={name.id}>
                                <Checkbox checked={brandName.indexOf(name.id) > -1} />
                                <ListItemText primary={name.name} />
                              </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </div>


                    {/*<div className="form-group">*/}
                    {/*  <select*/}
                    {/*      className="form-control"*/}
                    {/*      id="branch"*/}
                    {/*      onChange={handleChangeBranch}*/}
                    {/*      disabled={disableBranchs}*/}
                    {/*  >*/}
                    {/*    <option value="0">Todas las sucursal</option>*/}

                    {/*    {branches.map(branch => {*/}
                    {/*      return <option key={branch.id} value={branch.id}>{branch.name}</option>*/}
                    {/*    })}*/}

                    {/*  </select>*/}
                    {/*</div>*/}
                  </div>

                  <div className="mt-3 col-12 text-right ">
                    <button className="border-none btn btn-primary btn-login  w-100 font-10">
                      Registrar
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <ModalInfo message={error}></ModalInfo>
      </Fragment>
  );
}
