import React from "react";
import XLSX from 'xlsx';
import { Auth } from "aws-amplify";

const COLS = [
	{ key: 0, name: 'Grupo [A/C/G/M]' },
	{ key: 1, name: 'Ceco' },
	{ key: 2, name: 'Tienda' },
	{ key: 3, name: 'Email' },
	{ key: 4, name: 'Password' },
	{ key: 5, name: 'Pais' },
	{ key: 6, name: 'Marca' },
	// { key: 7, name: 'Otros [Json]' },
	{ key: 7, name: 'Status' },
	{ key: 8, name: 'Pago' },
]

const HEADER = ['Grupo [A/C/G/M]', 'Ceco', 'Tienda', 'Email', 'Password', 'Pais', 'Marca', 'Status'];

export default function SheetJSApp() {

	const [data, setData] = React.useState([]);
	const [dataPost, setDatPost] = React.useState([]);
	const [cols, setCols] = React.useState([]);

	const [isComplete, setComplete] = React.useState(false);


	const handleFile = (file/*:File*/) => {
		setDatPost([]);
		setCols([]);
		setComplete(false);

		/* Boilerplate to set up FileReader */
		console.info("Se subio Archivo");
		const reader = new FileReader();
		const rABS = !!reader.readAsBinaryString;
		reader.onload = (e) => {
			/* Parse data */
			const bstr = e.target.result;
			const wb = XLSX.read(bstr, { type: rABS ? 'binary' : 'array' });
			/* Get first worksheet */
			const wsname = wb.SheetNames[0];
			const ws = wb.Sheets[wsname];
			/* Convert array of arrays */
			const data = XLSX.utils.sheet_to_json(ws, { header: 1 });
			data.shift();

			console.info("data::");
			console.info(data);
			/* Update state */
			// this.setState({ data: data, cols: make_cols(ws['!ref']) });
			// this.setState({ data: data });
			setData(data);
		};
		if (rABS) reader.readAsBinaryString(file); else reader.readAsArrayBuffer(file);
	};


	const insertUsers = async  event => {
		event.preventDefault();

		const newUsers = [];

		const ALTA_USUARIOS = data;

		await Promise.all(ALTA_USUARIOS.map(async (user, index) => {

			let errors = validateParams(user);

			if (errors.length === 0) {
				const contents = await callApiAWS(user);
				user[7] = contents;
				newUsers[index] = user;
			} else {
				user[7] = `Falta ${errors.toString()}`;
				newUsers[index] = user;
			}

		}));

		setDatPost(newUsers);
		setCols(COLS);
		setComplete(true);
	}


	const callApiAWS = async (user) => {

		console.info(user);

		try {

			const group = getGroup(user[0]);
			console.info("Group: " + group);
			console.info("Id Pais: " + user[5]);
			console.info("Id Marca: " + user[6]);
			console.info("Id Sucursal: " + user[1]);
			console.info("Sucursal: " + user[2]);
			console.info("Email: " + user[3]);
			console.info("Password: " + user[4]);
			console.info("Status: " + user[7]);
			// console.info("Otros: " + user[7]);

			// Crear usuario
			await Auth.signUp({
				username: user[3].trim(),
				password: user[4].trim(),
				attributes: {
					email: user[3].trim(),
					name: user[7].trim(),
					"custom:paymentMethod": String( user[8]),
					"custom:country": String(user[5]),
					"custom:brand": String(user[6]),
					"custom:branch": String(user[1]),
					"custom:group": group,
					"custom:options": '[]'
				}
			});

			console.info("Exito!!");

			return 'Creado';

		} catch (error) {
			return error.message;
		}
	}


	const getGroup = (group) => {
		switch (group) {
			case 'A':
				return 'Admin';
			case 'C':
				return 'Coordinador';
			case 'G':
				return 'Gerente';
			case 'M':
				return 'Marca';
		}

	}

	const validateParams = (user) => {
		// console.info("****************************");
		// console.info("Validaciones::::");
		let errors = [];

		// Grupo [A/C/G/M]
		if (user[0] === undefined || user[0] === '') {
			errors.push("Grupo");
		}

		// Email
		if (user[3] === undefined || user[3] === '') {
			errors.push("Email");
		}

		// Password
		if (user[4] === undefined || user[4] === '') {
			errors.push("Password");
		}

		// Pais
		if (user[5] === undefined || user[5] === '') {
			errors.push("Pais");
		}


		if (user[0] !== undefined && (user[0] !== 'A' && user[0] !== 'C')) {

			// Marca
			if (user[6] === undefined || user[6] === '') {
				errors.push("Marca");
			}

			if (user[0] !== 'G') {
				// Ceco
				if (user[1] === undefined || user[1] === '') {
					errors.push("Ceco");
				}

				// Tienda
				if (user[2] === undefined || user[2] === '') {
					errors.push("Tienda");
				}
			}
		}

		return errors;
	}



	const exportFile = () => {
		/* convert state to workbook */
		const file = data;
		file.unshift(HEADER);
		const ws = XLSX.utils.aoa_to_sheet(file);
		const wb = XLSX.utils.book_new();
		XLSX.utils.book_append_sheet(wb, ws, "SheetJS");
		/* generate XLSX file and send to client */
		XLSX.writeFile(wb, "sheetjs.xlsx")
	};

	const cleanValues = () => {
		console.info("Clean Values");
	}


	return (
		<DragDropFile handleFile={handleFile}>
			<div className="row">
				<div className="col-xs-12">
					<DataInput handleFile={handleFile} cleanValues={cleanValues} />
				</div>
			</div>

			{(!isComplete) ?
				<div className="row">
					<div className="col-12 text-right">
						<button disabled={!data.length} className="btn btn-success" onClick={insertUsers}>Registrar Usuarios</button>
					</div>
				</div> : null
			}


			{(dataPost.length > 0) ?
				<div className="row">
					<div className="col-xs-12">
						<OutTable data={dataPost} cols={cols} />
					</div>
				</div> : null
			}


			{(isComplete) ?
				<div className="row">
					<div className="col-xs-12">
						<button disabled={!data.length} className="btn btn-success" onClick={exportFile}>Exportar</button>
					</div>
				</div> : null
			}


		</DragDropFile>
	);
};



/* -------------------------------------------------------------------------- */

/*
  Simple HTML5 file drag-and-drop wrapper
  usage: <DragDropFile handleFile={handleFile}>...</DragDropFile>
    handleFile(file:File):void;
*/
class DragDropFile extends React.Component {
	constructor(props) {
		super(props);
		this.onDrop = this.onDrop.bind(this);
	};
	suppress(evt) { evt.stopPropagation(); evt.preventDefault(); };
	onDrop(evt) {
		evt.stopPropagation(); evt.preventDefault();
		const files = evt.dataTransfer.files;
		if (files && files[0]) this.props.handleFile(files[0]);
	};
	render() {
		return (
			<div onDrop={this.onDrop} onDragEnter={this.suppress} onDragOver={this.suppress}>
				{this.props.children}
			</div>
		);
	};
};

/*
  Simple HTML5 file input wrapper
  usage: <DataInput handleFile={callback} />
    handleFile(file:File):void;
*/
class DataInput extends React.Component {
	constructor(props) {
		super(props);
		this.handleChange = this.handleChange.bind(this);
	};
	handleChange(e) {
		const files = e.target.files;
		if (files && files[0]) this.props.handleFile(files[0]);
	};
	render() {
		return (
			<form className="md-form custom-file">
				<div class="custom-file">
					<input type="file" class="custom-file-input" id="validatedCustomFile" required id="file" accept={SheetJSFT} onChange={this.handleChange} />
					<label class="custom-file-label" for="validatedCustomFile">Eleje el archivo...</label>
					<div class="invalid-feedback">Archivo invalido</div>
				</div>
			</form>

		);
	};
}




/*
  Simple HTML Table
usage: <OutTable data={data} cols={cols} />
		data:Array<Array<any> >;
cols:Array<{name: string, key:number|string}>;
		*/
class OutTable extends React.Component {
	constructor(props) { super(props); };
	render() {
		return (
			<div className="table-responsive">
				<table className="table table-striped">
					<thead>
						<tr>{this.props.cols.map((c) => <th key={c.key}>{c.name}</th>)}</tr>
					</thead>

					<tbody>
						{this.props.data.map((r, i) => <tr key={i}>
							{this.props.cols.map(c => <td key={c.key}>{r[c.key]}</td>)}
						</tr>)}
					</tbody>
				</table>
			</div>
		);
	};
};




/* list of supported file types */
const SheetJSFT = [
	"xlsx", "xlsb", "xlsm", "xls", "xml", "csv", "txt", "ods", "fods", "uos", "sylk", "dif", "dbf", "prn", "qpw", "123", "wb*", "wq*", "html", "htm"
].map(function (x) { return "." + x; }).join(",");

/* generate an array of column objects */
const make_cols = refstr => {
	let o = [], C = XLSX.utils.decode_range(refstr).e.c + 1;
	for (var i = 0; i < C; ++i) o[i] = { name: XLSX.utils.encode_col(i), key: i }
	return o;
};
