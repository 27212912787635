import 'date-fns';
import esLocale from "date-fns/locale/es";
import React, { useState, useEffect } from 'react';
import mercadoPago from './../images/general/mercadopago.png';
import openpay from './../images/general/openpay.png';
import wow from './../images/general/wow.png';
import rappi from './../images/general/rappi.png';
import DownloadExcel from './../components/DownloadExcel';
import LoadingBlack from './../components/LoadingBlack'
import api from "../services/ServiceGeneral";
import Swal from 'sweetalert2';
import Selects from "../components/Selects";

import Grid from '@material-ui/core/Grid';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';

import {
  PAYMENT_METHOD_ID_MERCADOPAGO,
  PAYMENT_METHOD_ID_WOW,
  PAYMENT_METHOD_ID_RAPPI,
  PAYMENT_METHOD_ID_OPENPAY,
  BRANDS
} from "../constants/constants"
import Select from "@material-ui/core/Select";



function Dashboard(props) {
  // sate
  const [val] = useState(props.auth);
  const [isLoading, guardarIsLoading] = useState(false);
  const [countryId] = useState(1); // DEFAULT MEXICO

  const [mercadoPagoData, setMercadoPagoData] = useState([]);
  const [fromDateMP, setFromDateMP] = useState(null);
  const [toDateMP, setToDateMP] = useState(null);
  const [brandMP, setBrandMP] = useState(0);


  const [wowData, setWowData] = useState([]);
  const [fromDateWow, setFromDateWow] = useState(null);
  const [toDateWow, setToDateWow] = useState(null);
  const [brandWow, setBrandWow] = useState(0);

  const [openpayData, setOpenpayData] = useState([]);
  const [fromDateOpenPay, setFromDateOpenPay] = useState(null);
  const [toDateOpenPay, setToDateOpenPay] = useState(null);
  const [brandOpenPay, setBrandOpenPay] = useState(0);

  const [rappiData, setRappiData] = useState([]);
  const [fromDateRappi, setFromDateRappi] = useState(null);
  const [toDateRappi, setToDateRappi] = useState(null);
  const [brandRappi, setBrandRappi] = useState(0);

  const getPayments = async (fromDate, toDate, paymentId, brandId) => {

    guardarIsLoading(true);
    let dataPayments = [];

    try {
      if (fromDate !== null && toDate !== null && brandId !== 0) {

        if (validateTwoDates(fromDate, toDate)) {

          const dataToken = await api.services.getTokenPayments();
          const data = await api.services
              .getPayments(formatDateSql(fromDate), formatDateSql(toDate), paymentId, dataToken.access_token, brandId, countryId);

          if (Object.keys(data).length === 0) {
            Swal.fire({
              title: 'Sin Registros',
              text: 'No existen registros que cumplan el filtro',
              type: 'warning'
            })
          } else {
            dataPayments = data;
          }

        }
      }
    } catch (error) {
      dataPayments = [];
    }

    guardarIsLoading(false);
    return dataPayments;
  }


  // MercadoPago
  useEffect(() => {
    const consultarAPIMP = async () => {
      const data = await getPayments(fromDateMP, toDateMP, PAYMENT_METHOD_ID_MERCADOPAGO, brandMP);
      setMercadoPagoData(data);
    }
    consultarAPIMP();
  }, [fromDateMP, toDateMP, brandMP]);


  // Wow
  useEffect(() => {
    const consultarAPI = async () => {
      const data = await getPayments(fromDateWow, toDateWow, PAYMENT_METHOD_ID_WOW, brandWow);
      setWowData(data);
    }
    consultarAPI();
  }, [fromDateWow, toDateWow, brandWow]);

  useEffect(() => {
    // console.info(val);
  }, [val]);

  // OpenPay
  useEffect(() => {
    const consultarAPI = async () => {
      const data = await getPayments(fromDateOpenPay, toDateOpenPay, PAYMENT_METHOD_ID_OPENPAY, brandOpenPay);
      setOpenpayData(data);
    }
    consultarAPI();
  }, [fromDateOpenPay, toDateOpenPay, brandOpenPay]);

  // Rappi
  useEffect(() => {
    const consultarAPI = async () => {
      const data = await getPayments(fromDateRappi, toDateRappi, PAYMENT_METHOD_ID_RAPPI, brandRappi);
      setRappiData(data);
    }
    consultarAPI();
  }, [fromDateRappi, toDateRappi, brandRappi]);

  const validateTwoDates = (first, second) => {

    if (first !== null && second !== null && (new Date(first).getTime() <= new Date(second).getTime())) {
      return true;
    }
    return false;
  }

  const formatDateSql = (date) => {
    return date.getFullYear() + "-" + ("0" + (date.getMonth() + 1)).slice(-2) + "-" + ("0" + date.getDate()).slice(-2);
  }

  const formatDateReport = (date) => {
    if (date != null) {
      return `${date.getFullYear()}${("0" + (date.getMonth() + 1)).slice(-2)}${("0" + date.getDate()).slice(-2)}`;
    }
  }

  console.info(props.auth.params.brandId);
  let servBrands=props.auth.params.brandId;
  if(servBrands[0].length===1 && Number(servBrands[0])===0){
    servBrands=["1","2","3","4","5","6","7","8","9"]
  }
  let USERBRANDS= BRANDS.filter((e)=>servBrands.indexOf(String(e.id)) > -1);
  return (

      <div className="container-fluid">
        <div className="row">
          <div className="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-1"></div>
          <div className="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-10">
            <div className="row mt-4 justify-content-center">
              {(val.params.paymentMethod.indexOf('137')>=0) &&
              <div className="card-container">
                <div className="card-select card-animate card-color-style">
                  <img src={mercadoPago} alt="mercado pago"/>
                  <div>

                    {(USERBRANDS && USERBRANDS.length > 0) ?
                        <div className="Dash-Item-Form ">
                          <Selects
                              list={USERBRANDS}
                              label="Sucursal"
                              id="branch"
                              onChange={(e) => setBrandMP(e.target.value)}
                          />
                        </div>
                        : null
                    }

                    <MuiPickersUtilsProvider locale={esLocale} utils={DateFnsUtils}>
                      <Grid container justify="space-around">
                        <KeyboardDatePicker
                            autoOk="true"
                            margin="normal"
                            id="date-picker-dialog"
                            label="Fecha inicial"
                            format="MM/dd/yyyy"
                            value={fromDateMP}
                            onChange={date => {
                              setFromDateMP(date)
                            }}
                            KeyboardButtonProps={{
                              'aria-label': 'change date',
                            }}
                            minDate={new Date('2019/11/01')}
                            maxDate={new Date()}
                            cancelLabel={'Cancelar'}
                            okLabel={'Aceptar'}
                        />

                        <KeyboardDatePicker
                            autoOk="true"
                            margin="normal"
                            locale="fr"
                            id="date-picker-dialog"
                            label="Fecha final"
                            format="MM/dd/yyyy"
                            value={toDateMP}
                            onChange={date => {
                              setToDateMP(date)
                            }}
                            KeyboardButtonProps={{
                              'aria-label': 'change date',
                            }}
                            minDate={new Date(fromDateMP)}
                            maxDate={new Date()}
                            cancelLabel={'Cancelar'}
                            okLabel={'Aceptar'}
                        />

                      </Grid>
                    </MuiPickersUtilsProvider>

                    <DownloadExcel dataSet={mercadoPagoData}
                                   brand={'mercadopago'}
                                   initDate={formatDateReport(fromDateMP)}
                                   endDate={formatDateReport(toDateMP)}
                    ></DownloadExcel>
                  </div>
                </div>
              </div>
              }
              {(val.params.paymentMethod.indexOf('4')>=0) &&
              <div className="card-container">
                <div className="card-select card-animate card-color-style">
                  <img src={wow} alt="wow" />
                  <div>

                    {(USERBRANDS && USERBRANDS.length > 0) ?
                        <div className="Dash-Item-Form ">
                          <Selects
                              list={USERBRANDS}
                              label="Sucursal"
                              id="branch"
                              onChange={(e) => setBrandWow(e.target.value)}
                          />
                        </div>
                        : null
                    }

                    <MuiPickersUtilsProvider locale={esLocale} utils={DateFnsUtils}>
                      <Grid container justify="space-around">
                        <KeyboardDatePicker
                            autoOk="true"
                            margin="normal"
                            id="date-picker-dialog"
                            label="Fecha inicial"
                            format="MM/dd/yyyy"
                            value={fromDateWow}
                            onChange={date => {
                              setFromDateWow(date)
                            }}
                            KeyboardButtonProps={{
                              'aria-label': 'change date',
                            }}
                            minDate={new Date('2019/11/01')}
                            maxDate={new Date()}
                            cancelLabel={'Cancelar'}
                            okLabel={'Aceptar'}
                        />

                        <KeyboardDatePicker
                            autoOk="true"
                            margin="normal"
                            locale="fr"
                            id="date-picker-dialog"
                            label="Fecha final"
                            format="MM/dd/yyyy"
                            value={toDateWow}
                            onChange={date => {
                              setToDateWow(date)
                            }}
                            KeyboardButtonProps={{
                              'aria-label': 'change date',
                            }}
                            minDate={new Date(fromDateWow)}
                            maxDate={new Date()}
                            cancelLabel={'Cancelar'}
                            okLabel={'Aceptar'}
                        />

                      </Grid>
                    </MuiPickersUtilsProvider>



                    <DownloadExcel dataSet={wowData}
                                   brand={'wow'}
                                   initDate={formatDateReport(fromDateWow)}
                                   endDate={formatDateReport(toDateWow)}
                    ></DownloadExcel>

                  </div>
                </div>
              </div>
              }
              {(val.params.paymentMethod.indexOf('3')>=0) &&
              <div className="card-container">
                <div className="card-select card-animate card-color-style">
                  <img src={openpay} alt="openpay" />
                  <div>

                    {(USERBRANDS && USERBRANDS.length > 0) ?
                        <div className="Dash-Item-Form ">
                          <Selects
                              list={USERBRANDS}
                              label="Sucursal"
                              id="branch"
                              onChange={(e) => setBrandOpenPay(e.target.value)}
                          />
                        </div>
                        : null
                    }

                    <MuiPickersUtilsProvider locale={esLocale} utils={DateFnsUtils}>
                      <Grid container justify="space-around">
                        <KeyboardDatePicker
                            autoOk="true"
                            margin="normal"
                            id="date-picker-dialog"
                            label="Fecha inicial"
                            format="MM/dd/yyyy"
                            value={fromDateOpenPay}
                            onChange={date => {
                              setFromDateOpenPay(date)
                            }}
                            KeyboardButtonProps={{
                              'aria-label': 'change date',
                            }}
                            minDate={new Date('2019/11/01')}
                            maxDate={new Date()}
                            cancelLabel={'Cancelar'}
                            okLabel={'Aceptar'}
                        />

                        <KeyboardDatePicker
                            autoOk="true"
                            margin="normal"
                            locale="fr"
                            id="date-picker-dialog"
                            label="Fecha final"
                            format="MM/dd/yyyy"
                            value={toDateOpenPay}
                            onChange={date => {
                              setToDateOpenPay(date)
                            }}
                            KeyboardButtonProps={{
                              'aria-label': 'change date',
                            }}
                            minDate={new Date(fromDateOpenPay)}
                            maxDate={new Date()}
                            cancelLabel={'Cancelar'}
                            okLabel={'Aceptar'}
                        />
                      </Grid>
                    </MuiPickersUtilsProvider>



                    <DownloadExcel dataSet={openpayData}
                                   brand={'openpay'}
                                   initDate={formatDateReport(fromDateOpenPay)}
                                   endDate={formatDateReport(toDateOpenPay)}
                    ></DownloadExcel>

                  </div>
                </div>
              </div>
              }
              {(val.params.paymentMethod.indexOf('126')>=0) &&
              <div className="card-container">
                <div className="card-select card-animate card-color-style">
                  <img src={rappi} alt="rappi" />
                  <div>

                    {(USERBRANDS && USERBRANDS.length > 0) ?
                        <div className="Dash-Item-Form ">
                          <Selects
                              list={USERBRANDS}
                              label="Sucursal"
                              id="branch"
                              onChange={(e) => setBrandRappi(e.target.value)}
                          />
                        </div>
                        : null
                    }

                    <MuiPickersUtilsProvider locale={esLocale} utils={DateFnsUtils}>
                      <Grid container justify="space-around">
                        <KeyboardDatePicker
                            autoOk="true"
                            margin="normal"
                            id="date-picker-dialog"
                            label="Fecha inicial"
                            format="MM/dd/yyyy"
                            value={fromDateRappi}
                            onChange={date => {
                              setFromDateRappi(date)
                            }}
                            KeyboardButtonProps={{
                              'aria-label': 'change date',
                            }}
                            minDate={new Date('2019/11/01')}
                            maxDate={new Date()}
                            cancelLabel={'Cancelar'}
                            okLabel={'Aceptar'}
                        />

                        <KeyboardDatePicker
                            autoOk="true"
                            margin="normal"
                            locale="fr"
                            id="date-picker-dialog"
                            label="Fecha final"
                            format="MM/dd/yyyy"
                            value={toDateRappi}
                            onChange={date => {
                              setToDateRappi(date)
                            }}
                            KeyboardButtonProps={{
                              'aria-label': 'change date',
                            }}
                            minDate={new Date(fromDateRappi)}
                            maxDate={new Date()}
                            cancelLabel={'Cancelar'}
                            okLabel={'Aceptar'}
                        />
                      </Grid>
                    </MuiPickersUtilsProvider>

                    <DownloadExcel dataSet={rappiData}
                                   brand={'rappi'}
                                   initDate={formatDateReport(fromDateRappi)}
                                   endDate={formatDateReport(toDateRappi)}
                    ></DownloadExcel>

                  </div>
                </div>
              </div>
              }


            </div>

          </div>
          <div className="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-1"></div>
        </div>
        {(isLoading) ? <LoadingBlack></LoadingBlack> : null}
      </div>

  );
}


export default Dashboard;