export const STATUS_SIN_CAMBIOS = 'Sin cambios';
export const STATUS_APLICADO = 'Aplicado';
export const STATUS_MODIFICADO = 'Modificado';

export const TOTAL_SUCURSALES = 8;



export const PAYMENT_METHOD_ID_OPENPAY = 3;
export const PAYMENT_METHOD_ID_WOW = 4;
export const PAYMENT_METHOD_ID_INTERMEDIARIO = 5;
export const PAYMENT_METHOD_ID_CONTRAENTREGA = 6;
export const PAYMENT_METHOD_ID_RAPPI = 126;
export const PAYMENT_METHOD_ID_MERCADOPAGO = 137;


export const BRANDS = [
    {
      id: 1,
      name: 'BURGER KING'
    },
    {
      id: 2,
      name: 'CHILIS'
    },
    {
      id: 3,
      name: 'EL PORTON'
    },
    {
      id: 4,
      name: 'ITALIANNIS'
    },
    {
      id: 5,
      name: 'P.F. CHANGS'
    },
    {
      id: 6,
      name: 'STARBUCKS'
    },
    {
      id: 7,
      name: 'THE CHEESCAKE FACTORY'
    },
    {
      id: 8,
      name: 'VIPS'
    },
    {
      id: 9,
      name: 'DOMINOS'
    },
  ]